<template>
  <section class="payment">
    <div v-if="subscriptionPlans.length && activePlan" class="container">
      <h1 class="payment__title">Enter Payment Information</h1>

      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12">
          <div class="col-12 headtitle">
            <div v-if="TrialDays != '' && TrialDays > 0">
              <span class="f1">14-day Full Access </span
              ><span class="f2">${{ InitialPrice }}</span>
            </div>
            <div v-else>
              <span class="f1">Monthly Access</span>
              <span class="f2">${{ Price }}</span>
            </div>
          </div>

          <form class="payment__form" @submit.prevent="sendPayment">
            <div class="row">
              <div class="col-lg-12 col-sm-12 form-col">
                <div class="payment__services">
                  <div class="secure_checkout">
                    <AppIcon componentName="LockIcon" class="lock-icon" />
                    Secure Checkout
                  </div>
                  <img
                    class="credit-cards desktopz"
                    src="~@/assets/images/payment-icons.png"
                    alt="credit-cards logo"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 form-col">
                <AppField
                  label="First name"
                  inputHeight="sm"
                  required
                  :value="first_name"
                  :isInvalid="$v.first_name.$error"
                  @input="setField($event, 'first_name')"
                >
                  <template v-slot:error>
                    <p v-if="!$v.first_name.required">This field is required</p>
                  </template>
                </AppField>
              </div>
              <div class="col-lg-6 col-sm-12 form-col">
                <AppField
                  label="Last name"
                  inputHeight="sm"
                  required
                  :value="last_name"
                  :isInvalid="$v.last_name.$error"
                  @input="setField($event, 'last_name')"
                >
                  <template v-slot:error>
                    <p v-if="!$v.first_name.required">This field is required</p>
                  </template>
                </AppField>
              </div>
              <div class="col-lg-8 col-sm-12 form-col">
                <AppFieldCardNumber
                  label="Card number"
                  inputHeight="sm"
                  required
                  :isInvalid="$v.cc_num.$error"
                  @input="setField($event, 'cc_num')"
                >
                  <template v-slot:error>
                    <p v-if="!$v.cc_num.required">This field is required</p>
                  </template>
                </AppFieldCardNumber>
              </div>
              <div class="col-lg-4 col-sm-12 form-col">
                <AppFieldNumber
                  label="CVV"
                  inputHeight="sm"
                  required
                  maxLength="4"
                  :isInvalid="$v.cvv.$error"
                  @input="setField($event, 'cvv')"
                >
                  <template v-slot:error>
                    <p v-if="!$v.cvv.required">This field is required</p>
                    <p v-if="!$v.cvv.minLength || !$v.cvv.maxLength">
                      Invalid CVV
                    </p>
                  </template>
                </AppFieldNumber>
              </div>
              <div class="col-lg-8 col-sm-12 form-col pmt2">
                <date-picker
                  placeholder="Month (MM)"
                  popupClass="month-picker"
                  ref="datepicker"
                  type="month"
                  format="YYYY-MM-DDTHH:mm:ss.sss"
                  value-type="format"
                  v-model="expiry_month"
                  @change="setField($event, 'expiry_month')"
                >
                  <template v-slot:input>
                    <AppDateField
                      class="creation-form-field"
                      label="Month"
                      :value="expiry_month"
                      required
                      type="month"
                      :isInvalid="$v.expiry_month.$error"
                      @change="setField($event, 'expiry_month')"
                      isBlockedWriting
                    >
                      <template v-slot:error>
                        <p v-if="!$v.expiry_month.required">
                          This field is required
                        </p>
                        <p
                          v-if="
                            !$v.expiry_month.minLength ||
                            !$v.expiry_month.maxLength ||
                            !$v.expiry_month.minValue ||
                            !$v.expiry_month.maxValue
                          "
                        >
                          Invalid Month
                        </p>
                      </template>
                    </AppDateField>
                  </template>
                </date-picker>
              </div>
              <div class="col-lg-4 col-sm-12 form-col pmt2">
                <date-picker
                  placeholder="Year (YYYY)"
                  ref="datepicker"
                  v-model="expiry_year"
                  type="year"
                  format="YYYY-MM-DDTHH:mm:ss.sss"
                  value-type="format"
                  @change="setField($event, 'expiry_year')"
                >
                  <template v-slot:input>
                    <AppDateField
                      class="creation-form-field"
                      label="Year"
                      required
                      type="year"
                      isBlockedWriting
                      :value="expiry_year"
                      :isInvalid="$v.expiry_year.$error"
                      @change="setField($event, 'expiry_year')"
                    >
                      <template v-slot:error>
                        <p v-if="!$v.expiry_year.required">
                          This field is required
                        </p>
                        <p
                          v-if="
                            !$v.expiry_year.minLength ||
                            !$v.expiry_year.maxLength
                          "
                        >
                          Invalid Year
                        </p>
                      </template>
                    </AppDateField>
                  </template>
                </date-picker>
              </div>

              <div class="col-12 form-col">
                <AppButton
                  type="submit"
                  title="Download My Resume"
                  className="primary"
                  class="payment__submit"
                />
              </div>
            </div>
            <div class="form-footer">
              <div class="row">
                <div class="col-12 form-col">
                  <img
                    class="credit-cards mobilez"
                    src="~@/assets/images/payment-icons.png"
                    alt="credit-cards logo"
                  />
                  <div class="form-footer__item">
                    <div class="form-footer__icon">
                      <AppIcon componentName="GuardIcon" />
                    </div>
                    <p class="form-footer__text">
                      All of your information is encrypted. We use SSL 128-bit
                      encryption on all pages of Resume-Perfect.com.
                    </p>
                  </div>
                  <div class="form-footer__item">
                    <div class="form-footer__icon">
                      <AppIcon componentName="WarningIcon" />
                    </div>
                    <p class="form-footer__text">
                      By clicking "Download My Resume" above you agree to be
                      charged
                      <span v-if="TrialDays != '' && TrialDays > 0"
                        >${{ InitialPrice }}</span
                      >
                      <span v-else>${{ Price }}</span>
                      now and accept our
                      <a
                        class="form-footer__link"
                        target="_blank"
                        :href="ROUT_PATH.TERMS_AND_CONDITIONS"
                        >Terms</a
                      >
                      of Use and
                      <a
                        class="form-footer__link"
                        target="_blank"
                        :href="ROUT_PATH.PRIVACY_POLICY"
                        >Privacy Policy.</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="payment__info payment__form">
            <div v-if="TrialDays != '' && TrialDays > 0">
              <h2 class="payment__heading">
                How our {{ TrialDays }}-Day Trial Works
              </h2>
              <p class="payment__text">
                After the ${{ InitialPrice }} trial, you will automatically
                receive a premium memberships at ${{ Price }} per month and your
                service will continue uninterrupted unless you decide to cancel.
              </p>
            </div>
            <h2 class="payment__heading">Satisfaction Guaranteed</h2>
            <p class="payment__text">
              We've designed Resume-Perfect to let you build resumes and cover
              letters that get you hired. If you're not happy for any reason,
              just contact us and we'll refund your money.
            </p>
          </div>
        </div>
      </div>
    </div>
    <AppModal
      width="514px"
      :isOpenModal="isModalOpen"
      @close="showModal(false)"
    >
      <div class="pay-modal">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="pay-modal__icon">
              <AppIcon componentName="PaySuccessIcon" />
            </div>
            <h3 class="pay-modal__title">Payment successful</h3>
            <p class="pay-modal__description">
              You can manage your subscriptions in User Profile. Your
              subscription will be renewed on {{ expiresDate }}
            </p>
            <AppButton
              title="Back to Dashboard"
              className="primary"
              class="pay-modal__btn"
              tag="router-link"
              :to="ROUT_PATH.USER_DASHBOARD"
            />
          </div>
        </div>
      </div>
    </AppModal>
  </section>
</template>

<script>
import $ from "jquery";

import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";
import {
  email,
  minLength,
  maxLength,
  minValue,
  maxValue,
  required,
} from "vuelidate/lib/validators";

import {
  ROUT_PATH,
  USER_ACCOUNT_NAMES,
  SUBSCRIBE_STEPS_NAMES,
} from "@/helpers/rout-constants";
import DatePicker from "vue2-datepicker";
import CompletedTemplate from "@/helpers/completed-template-hash";
import AppToast from "@/helpers/toast-methods";
import CookieHelper from "@/helpers/cookie-helper";
import AppDateField from "@/components/ui/AppDateField";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

export default {
  name: "PaymentPage",
  mixins: [mixpanelMixin],
  components: {
    DatePicker,
    AppDateField,
  },

  data() {
    return {
      ROUT_PATH,
      activePlanId: null,
      activePlan: null,
      activeConfig: null,
      TrialDays: null,
      Price: null,
      InitialPrice: null,
      Plan: null,
      isModalOpen: false,
      first_name: "",
      last_name: "",
      cc_num: "",
      cvv: "",
      expiry_month: "",
      expiry_year: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      expiresDate: "user/expiresDate",
      subscriptionPlans: "subscribe/getSubscriptions",
    }),
  },
  validations: {
    first_name: {
      required,
    },
    last_name: {
      required,
    },
    cc_num: {
      required,
    },
    expiry_month: {
      required,
    },
    expiry_year: {
      required,
    },
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
    },
  },

  async created() {
    await this.$store.dispatch("loader/pending");
    await this.getUserData();

    if (this.user.payment_subscription[0] !== undefined) {
      let payment_plan = this.user.payment_subscription[0].identity;

      if (payment_plan.trim() != "") {
        await this.$store.dispatch("loader/done");
        await this.$router.push({
          name: USER_ACCOUNT_NAMES.USER_DASHBOARD,
        });
        return;
      }
    }

    this.mixpanelTrack("V2-PAYMENTPAGE");

    const plans = await this.$store.dispatch(
      "subscribe/getAllSubscriptionPlans"
    );

    this.activePlanId = this.$route.params.planId || plans[0].identity;

    this.activePlan = plans.find(
      (price) => price.identity === this.activePlanId
    );

    let config = JSON.parse(this.activePlan.config);
    this.TrialDays = config[0].trial_days;
    this.Plan = config[0].plan;

    if (config[0].price > 0) {
      this.Price = (config[0].price / 100).toFixed(2);
    }
    if (config[0].initial_price > 0) {
      this.InitialPrice = (config[0].initial_price / 100).toFixed(2);
    }

    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;

    await this.getExpiresDate();
    await this.$store.dispatch("loader/done");
  },

  methods: {
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }
    },

    setField(fieldData, fieldName) {
      this[fieldName] = fieldData;
    },

    async getExpiresDate() {
      if (this.user) return;
      try {
        await this.$store.dispatch("user/getUser");
      } catch (err) {
        console.error(err);
      }
    },
    defineActiveClass(planId) {
      return planId === this.activePlan.identity
        ? "payment-tab__card--active"
        : "";
    },
    pickPlan(plan) {
      // this.activePlan = plan;
      this.$set(this, "activePlan", plan);
    },
    showModal(show) {
      this.isModalOpen = show;
    },
    isValidExpiry(exYear, exMonth) {
      var today, someday;
      today = new Date();
      someday = new Date();
      someday.setFullYear("20" + exYear, exMonth, 0);

      if (someday < today) {
        return false;
      } else {
        return true;
      }
    },

    scrollIntoFirstError() {
      this.$nextTick(() => {
        const firstInvalidEl = document.querySelector(".field--error");
        if (!firstInvalidEl) return;
        window.scroll({
          top:
            firstInvalidEl.getBoundingClientRect().top + window.scrollY - 100,
          behavior: "smooth",
        });
        firstInvalidEl.querySelector("input").focus();
      });
    },
    async sendPayment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollIntoFirstError();
        return;
      }

      const month = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];

      const formattedTime = [];
      const exp_month = new Date(this.expiry_month);
      const formatted_exp_month = month[exp_month.getMonth()];

      let formatted_exp_year = "";
      const exp_year = new Date(this.expiry_year);
      formatted_exp_year = ` ${exp_year.getFullYear()}`;
      formatted_exp_year = formatted_exp_year.slice(-2);

      if (!this.isValidExpiry(formatted_exp_year, formatted_exp_month)) {
        AppToast.toastError("Invalid expiry year.");
        return;
      }

      let mode = CookieHelper.getFlag("mode", "live");

      await this.$store.dispatch("loader/pending");
      try {
        const payload = {
          up_to_date: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
          subscription_plan: this.activePlan.identity,
          subscription_title: this.activePlan.title,
          first_name: this.first_name,
          last_name: this.last_name,
          cc_num: this.cc_num,
          cvv: this.cvv,
          expiry_month: formatted_exp_month,
          expiry_year: formatted_exp_year,
          mode: mode,
        };

        await this.$store.dispatch("subscribe/subscribeUser", payload);
        await this.getExpiresDate();

        window.location = "/thankyou";
      } catch (err) {
        console.log(err);
      }
      await this.$store.dispatch("loader/done");
    },
  },
};
</script>

<style scoped lang="scss">
.mx-datepicker {
  width: 100% !important;
}
.field {
  @media (max-width: map_get($grid-breakpoints, md)) {
    margin-bottom: 10px !important;
  }
  &__input {
    @media (max-width: map_get($grid-breakpoints, md)) {
      height: 45px !important;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}

img.credit-cards.mobilez {
  margin: 0 auto;
  padding: 20px 0 0;
}
.desktopz {
  display: block !important;
  width: 300px;
  @media (max-width: map_get($grid-breakpoints, md)) {
    display: none !important;
  }
}
.mobilez {
  display: none !important;
  @media (max-width: map_get($grid-breakpoints, md)) {
    display: block !important;
    width: 100%;
  }
}

.headtitle {
  max-width: 890px;
  padding: 10px 32px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
  margin: 0 auto 10px;
  border: 3px solid #4874e6;
  min-height: 54px;
}

span.f1 {
  float: left;
  color: #4874e6;
  font-weight: bolder;
  font-size: 18px;
}

span.f2 {
  float: right;
  color: #4874e6;
  font-weight: bolder;
  font-size: 18px;
}

.payment {
  padding: 40px 0 69px;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    padding: 15px 0 60px;
  }

  &__title {
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: map_get($grid-breakpoints, sm)) {
      font-size: 18px;
      margin-bottom: 0;
    }
    @media (max-width: map_get($grid-breakpoints, md)) {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 1.3;
    }
  }

  .payment-tab {
    &__col {
      @media (max-width: map_get($grid-breakpoints, md)) {
        padding: 0 5px;
      }
    }

    &__card {
      min-height: 125px;
      border: 1px solid #dadada;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 32px;
      cursor: pointer;
      transition: all 0.5s ease;

      @media (min-width: map_get($grid-breakpoints, lg)) {
        min-width: 141px;
        margin-bottom: 62px;
      }

      span {
        font-size: 12px;
        line-height: 140%;
        color: $light-gray;
        margin-bottom: 4px;
        transition: color 0.5s ease;
      }

      &--active {
        background-color: $primary;
        color: white;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
        border: 2px solid $primary;

        span {
          color: white;
        }
      }
    }

    &__duration {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 4px;
    }

    &__price {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 4px;
    }
  }

  &__form {
    padding: 32px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: 0 auto 50px;
    @media (max-width: 320px) {
      padding-top: 16px;
    }
    @media (min-width: map_get($grid-breakpoints, lg)) {
      max-width: 890px;
      margin-top: 0;
    }

    @media (max-width: map_get($grid-breakpoints, md)) {
    }

    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 32px;
    }

    .form-col {
      padding: 0 10px;

      @media (max-width: map_get($grid-breakpoints, lg)) {
        padding: 0 6px;
      }
    }

    .form-footer {
      color: $light-gray;
      font-size: 14px;

      &__icon {
        margin-right: 10px;
      }

      &__item {
        display: flex;
        margin-top: 32px;
      }

      &__link {
        color: $primary;
        cursor: pointer;
      }
    }
  }

  &__info {
    @media (max-width: map_get($grid-breakpoints, lg)) {
      text-align: center;
    }
    margin-top: 20px auto 0;
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 16px;
    margin-top: 0px;
    @media (max-width: map_get($grid-breakpoints, md)) {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0px !important;
    }
    &:first-of-type {
      margin: 20px auto;

      @media (max-width: map_get($grid-breakpoints, lg)) {
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
    margin: 15px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      max-width: 800px;
    }
  }

  &__price {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;

    @media (max-width: map_get($grid-breakpoints, lg)) {
      font-size: 40px;
    }
    @media (max-width: map_get($grid-breakpoints, sm)) {
      font-size: 18px;
      margin-left: 8px;
      margin-bottom: 8px;
    }
    @media (max-width: map_get($grid-breakpoints, md)) {
      font-size: 30px;
    }
  }

  &__submit {
    min-width: 100%;
  }

  &__form &__services {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 24px;
    .secure_checkout {
      font-size: 14px;
      display: flex;
      align-items: flex-end;
      .lock-icon {
        fill: #f4be44;
        padding: 0px 5px 5px 5px;
        width: 25px;
        height: 25px;
      }
    }
    @media (max-width: 320px) {
      margin-bottom: 12px;
    }
  }
}

@media (max-width: 576px) {
  .payment__form .payment__services {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    .secure_checkout {
      margin-bottom: 5px;
    }
  }
}
@media (max-width: 320px) {
  .payment__form .payment__services {
    .secure_checkout {
      margin-bottom: 12px;
    }
    img {
      width: 250px;
    }
  }
  span.f1,
  span.f2 {
    font-size: 14px;
  }
  img.credit-cards.mobilez {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .payment__form .payment__services .credit-cards {
    width: 100%;
  }
}
::v-deep .modal {
  padding: 40px;
  min-height: 405px;
}

.pay-modal {
  text-align: center;

  &__btn {
    width: 100%;
    margin-top: 40px;
  }

  &__description {
    font-size: 14px;
    line-height: 150%;
    color: $light-gray;
    margin-top: 16px;
  }

  &__title {
    line-height: 130%;
    margin-top: 40px;
  }

  &__icon {
    margin-top: 50px;
  }
}
</style>
